<template>
  <div class="msg-manage">
    <x-table
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>

    <Modal v-model="detailModel" title="消息详情" footer-hide>
      <Form :label-width="110">
        <FormItem label="消息标题：">
          <span>{{ this.detail.messageTitle }}</span>
        </FormItem>
        <FormItem label="消息内容：">
          <span>{{ this.detail.messageContent }}</span>
        </FormItem>
        <FormItem label="发送时间：">
          <span>{{ this.detail.createDate }}</span>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      table: {
        columns: [
          {
            title: "消息标题",
            minWidth: 100,
            key: "messageTitle",
          },
          {
            title: "消息类型",
            minWidth: 100,
            key: 'templateKey'
          },
          {
            title: "消息内容",
            minWidth: 100,
            key: "messageContent",
          },
          {
            title: "发送时间",
            width: 180,
            key: "createDate",
          },
          {
            title: "状态",
            width: 100,
            render: (h, { row }) => {
              return <span>{row.isRead == 0 ? "未读" : "已读"}</span>;
            },
          },
          {
            title: "操作",
            width: 150,
            render: (h, { row }) => {
              return (
                <div>
                  <a
                    style="margin-right: 10px"
                    onClick={() => this.getDetail(row.id)}
                  >
                    详情
                  </a>
                  <Poptip
                    confirm
                    transfer
                    title="确定删除吗?"
                    on-on-ok={() => this.del(row.id)}
                  >
                    <a>删除</a>
                  </Poptip>
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
      },
      search_data: {},
      detail: {},
      detailModel: false,
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          width: 200,
          filterBox: [
            {
              conditionName: "消息类型",
              component: "select",
              field: "templateKey",
              data: [{name: '系统消息', id: '系统消息'}],
              defaultValue: "",
              parameterField: "id",
              showField: "name",
            },
          ],
        },
        page: this.page,
      };
      return config;
    },
  },
  methods: {
    search(data) {
      this.page.pageNo = 1;
      this.search_data = data;
      this.getList();
    },

    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList()
    },

    getList() {
      this.table.loading = true;
      this.$post(this.$api.MSG.LIST, {
        toUserId: localStorage.userId,
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    del(id) {
      this.$post(this.$api.MSG.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },
    getDetail(id) {
      this.$post(this.$api.MSG.VIEW, {
        id,
      }).then((res) => {
        this.getList();
        this.detail = res;
        this.detailModel = true;
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.msg-manage {
  width: 100%;
  height: 100%;
}
</style>